import React from "react"
import * as S from "./VideoHero.styles.jsx"
import parse from "html-react-parser"
import { StaticImage } from "gatsby-plugin-image"

const VideoHero = ({
  title,
  content,
  video,
  videoPoster,
  height,
  autoplay,
}) => {
  let full = title && !content ? "full" : ""
  return (
    <>
      <a
        style={{ margin: "0 auto" }}
        href="mailto:lchesal@imcritical.com?subject=SC24+Free+Passes"
      >
        <StaticImage
          style={{ maxWidth: "1000px", margin: "0 auto" }}
          src="../../images/sc24-imcritical.webp"
          alt="Join Us in Booth #4407 - SC24 Atlanta, GA"
        />
      </a>
      <S.Wrapper style={{ height: height ? height : "50vh" }}>
        <S.HeroBg>
          {video ? (
            <S.VideoBg
              className="bg"
              autoPlay={autoplay ? "autoplay" : false}
              loop
              muted
              src={video.localFile.publicURL}
              poster={
                videoPoster
                  ? videoPoster.localFile.childImageSharp.gatsbyImageData.images
                      .fallback.src
                  : false
              }
            />
          ) : videoPoster ? (
            <img
              className="bg"
              src={
                videoPoster.localFile.childImageSharp.gatsbyImageData.images
                  .fallback.src
              }
              alt=""
            />
          ) : (
            false
          )}
        </S.HeroBg>

        <div className="copy_container">
          <h1 className={full}>{parse(title)}</h1>
          {content && <div className="content">{parse(content)}</div>}
        </div>
      </S.Wrapper>
    </>
  )
}
export default VideoHero
